





























































import * as mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import * as _ from "lodash";
import Vue from "vue";

import { Feature } from '../../misc/GeoUtils';

declare var NC_CONFIG: any;

export default Vue.extend({
    props: {
        value: { type: Feature, required: false },
    },
    data() {
        var locationName = '';

        if (this.value) {
            locationName = this.value.place_name;
        }

        return {
            locationName: locationName,

            currentLocation: null as Feature,
            currentLocationError: false,

            matches: [] as Feature[],

            // Will be set to true when the next change of the location <input>
            // field should be ignored.
            skipAutocomplete: false,
            showAutocomplete: false,

            // The index of the autocomplete item that is currently selected.
            selected: null as number,
        }
    },
    mounted() {
        this.geocodingClient = mbxGeocoding({
            accessToken: NC_CONFIG.mapboxToken
        });

        // Detects when the user click outside of the input and autocomplete
        // box.
        let inputEl = this.$el.querySelector('input[type="text"]');
        let autocompleteEl = this.$el.querySelector('.location-autocomplete');

        let compnt = this;
        this.clickEventListener = function(event) {
            let target = event.target;
            if (!autocompleteEl.contains(target) && target != inputEl) {
                compnt.showAutocomplete = false;
            }
        }

        document.addEventListener('click', this.clickEventListener);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.clickEventListener);
    },
    methods: {
        // Updates the autocomplete results with Mapbox.
        autocomplete: _.debounce(function () {
            this.geocodingClient
                .forwardGeocode({
                    query: this.locationName,
                    limit: 15,
                })
                .send()
                .then(response => {
                    this.resetAutocomplete();
                    this.showAutocomplete = true;
                    this.matches = response.body.features.map(Feature.fromGeoJSON);
                });
        }, 300),

        resetAutocomplete() {
            this.matches = [];
            this.showAutocomplete = false;
            this.selected = null;
        },

        setLocation(location: Feature) {
            this.skipAutocomplete = true;
            this.resetAutocomplete();

            this.locationName = location.place_name;
            this.$emit('input', location);
        },

        setCurrentLocation() {
            if (!navigator.geolocation) {
                this.currentLocationError = true;
                return;
            }

            let compnt = this;

            function success(pos) {
                compnt.currentLocationError = false;

                compnt.geocodingClient
                    .reverseGeocode({
                        query: [pos.coords.longitude, pos.coords.latitude],
                        limit: 1,
                        types: [
                            'address', 'poi', 'country', 'region', 'district',
                            'place', 'locality', 'neighborhood'
                        ]
                    })
                    .send()
                    .then(response => {
                        if (response.body.features.length >= 0) {
                            compnt.currentLocationError = false;
                            compnt.currentLocation = Feature.fromGeoJSON(response.body.features[0]);
                            compnt.setLocation(compnt.currentLocation);
                        } else {
                            compnt.currentLocationError = true;
                        }
                    });
            }

            function error() {
                compnt.currentLocationError = true;
            }

            navigator.geolocation.getCurrentPosition(success, error);
        },

        // Handles keyboard ↑, ↓ and ↩︎ events to navigate autocomplete.

        keyEnter() {
            if (this.selected == null) {
                return;
            } else if (this.selected == 0) {
                this.setCurrentLocation();
            } else {
                this.setLocation(this.matches[this.selected - 1]);
            }
        },

        keyDown() {
            if (this.selected == null) {
                this.selected = 0;
            } else {
                this.selected = Math.min(
                    this.matches.length, this.selected + 1
                );
            }
        },

        keyUp() {
            if (this.selected == null) {
                return
            } else if (this.selected == 0) {
                this.selected = null;
            } else {
                this.selected = Math.max(0, this.selected - 1);
            }
        }
    },
    watch: {
        locationName(newVal, oldVal) {
            if (this.skipAutocomplete) {
                this.skipAutocomplete = false;
                return;
            }

            if (oldVal != newVal) {
                this.matches = [];

                if (newVal.length > 0) {
                    this.autocomplete();
                }
            }
        }
    },
});
