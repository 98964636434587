var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid-x grid-padding-x" }, [
    _c("h1", { staticClass: "cell search-form-title" }, [
      _vm._v("\n        Book a rehearsal or recording studio\n    ")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "cell medium-12 large-8" }, [
      _c(
        "label",
        [
          _vm._v("\n            Location\n            "),
          _c("location-input", {
            ref: "locationInput",
            model: {
              value: _vm.location,
              callback: function($$v) {
                _vm.location = $$v
              },
              expression: "location"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { staticClass: "help-text" }, [
        _vm._v("\n            Or use your\n            "),
        _c(
          "button",
          {
            staticClass: "link",
            on: {
              click: function($event) {
                return _vm.setCurrentLocation()
              }
            }
          },
          [
            _vm._v("\n                current location  "),
            _c("i", { staticClass: "fi-marker" })
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "cell medium-12 large-4" }, [
      _c("label", { staticClass: "show-for-large" }, [
        _vm._v("\n             ")
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "button primary large expanded",
          attrs: { href: _vm.searchUrl }
        },
        [
          _c("i", { staticClass: "fi-magnifying-glass" }),
          _vm._v(" \n            Search\n        ")
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }