





















































import Vue from "vue";

declare var NC_ROUTES: any;

import LocationInput from '../widgets/LocationInput.vue';

import { Feature } from "../../misc/GeoUtils";

export default Vue.extend({
    props: {
    },
    data() {
        return {
            location: null as Feature
        }
    },
    computed: {
        searchUrl() {
            var url = NC_ROUTES.controllers.StudiosController.index().url;

            if (this.location) {
                url += '#' + this.location.serialize();
            }

            return url;
        },
    },
    methods: {
        setCurrentLocation() {
            this.$refs.locationInput.setCurrentLocation();

            return false;
        }
    },
    components: { LocationInput }
});
