var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "location-input" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.locationName,
          expression: "locationName"
        }
      ],
      attrs: { type: "text" },
      domProps: { value: _vm.locationName },
      on: {
        focus: function($event) {
          _vm.showAutocomplete = true
        },
        keyup: [
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.keyEnter()
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "down", 40, $event.key, [
                "Down",
                "ArrowDown"
              ])
            ) {
              return null
            }
            return _vm.keyDown()
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
            ) {
              return null
            }
            return _vm.keyUp()
          }
        ],
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.locationName = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _c(
      "ul",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showAutocomplete,
            expression: "showAutocomplete"
          }
        ],
        staticClass: "location-autocomplete"
      },
      [
        _c(
          "li",
          {
            class: { selected: _vm.selected == 0 },
            on: {
              click: function($event) {
                return _vm.setCurrentLocation()
              }
            }
          },
          [
            _c("i", { staticClass: "fi-marker" }),
            _vm._v("  \n\n            "),
            _vm.currentLocationError
              ? _c("span", [
                  _vm._v(
                    "\n                Unable to retrieve your location\n            "
                  )
                ])
              : _c("span", [
                  _vm._v(
                    "\n                Current location\n                "
                  ),
                  _vm.currentLocation != null
                    ? _c("span", [
                        _vm._v(
                          "\n                    (" +
                            _vm._s(_vm.currentLocation.place_name) +
                            ")\n                "
                        )
                      ])
                    : _vm._e()
                ])
          ]
        ),
        _vm._v(" "),
        _vm._l(_vm.matches, function(match, index) {
          return _c(
            "li",
            {
              key: match.place_name,
              class: { selected: index + 1 == _vm.selected },
              on: {
                click: function($event) {
                  return _vm.setLocation(match)
                }
              }
            },
            [_vm._v("\n            " + _vm._s(match.place_name) + "\n        ")]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }